input[type="radio"] {
    display: none;
}

.rev-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.splash-Reviews {
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  /* grid-gap: 20px; */
  justify-content: center;
  justify-items: center;
  row-gap: 2rem;
  align-items: center;
}

@media screen and (max-width: 1010px) {
    .splash-Reviews {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
    }
}

.prof-reviews {
    min-height: 40rem;
}

.all-rev-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 2px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  width: 75%;
  min-width: 200px;
  max-width: 75%;
  max-height: 40rem;
  height: 100%;
  text-decoration: none;
  box-shadow: 0px 0px 10px -5px;
  transition: .5s;
  border-radius: 5px;
  /* box-shadow:  4px 4px 4px 6px rgba(0, 0, 0, 0.1); */
}

.all-rev-card:hover {
    text-decoration: none;
    box-shadow: 0px 0px 10px -2px;
}

#rating-name {
    padding-left: 1rem;
    font-size: larger;
    color: rgba(2,122,151,1);
}

#rating-name:hover {
    text-decoration: underline;
}


.all-rev-card img {
    /* height: 15rem; */
    /* max-height: 15rem; */
    max-width: 100%;
    aspect-ratio: 3/2;
}

#name-on-rating {
    /* padding-top: 1rem; */
    /* padding-left: 1.3rem; */
    text-decoration: none;
    /* color: rgba(2,122,151,1); */
}

#name-on-rating:hover {
    text-decoration: none;
}

#rating {
    display: flex;
    align-items: center;
    padding-left: 1rem;
}

.all-rev-card p {
    min-height: 10%;
}

.rev-card p {
    overflow: hidden;
    /* width: 70%; */
}
.all-rev-card p {
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size:medium;
    font-weight: 500;
    color: rgba(45,46,47,1);
}

#reviewer {
    display: flex;
    gap: 1rem;
}
#reviewer img {
    height: 3rem;
    border-radius: 2rem;
}

#all-reviewer {
  display: flex;
  align-items: flex-start;
  padding-left: 8%;
  align-items: center;
  /* justify-content: center; */
  /* background-color: red; */
  /* border-bottom: 1px solid #e5e5e5; */
  gap: 1rem;
  text-decoration: none;
}

#all-reviewer img {
    height: 3rem;
    width: 3rem;
    border-radius: 2rem;
}
#add-ons {
    display: flex;
    gap: .5rem;
}




#rev-and-mutate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

#location-link:hover{
    text-decoration: underline;
}
