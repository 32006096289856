button:hover {
    cursor: pointer;
}

a:hover {
    cursor: pointer;
}



.body-container {
    width: 100%;
}

.body-container-items {
    display: flex;
    /* background-color: gray; */
    width: 100%;
    /* height: 82rem; */
}

.all-cards-container {
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
    width: 65%;
    height: 100%;
    max-height: 90vh;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    /* -ms-overflow-style: none; for Internet Explorer, Edge */
    /* scrollbar-width: none; for Firefox */
    overflow-y: auto;
}

.all-cards-container::-webkit-scrollbar{
    scroll-behavior: smooth;
}

.maps-api-container {
    width: 35%;
    height: 90vh;
    /* overflow:scroll; */
    /* background-color: blue; */
    /* height: fit-content; */
    /* object-fit: contain; */
    /* width: fit-content; */
}


/* .maps-api-container img {
    height: 100%;
    overflow: hidden;
} */

.filter-container {
width: 20%;
/* background-color: gray; */
max-height: 85vh;
display: flex;
flex-direction: column;
/* justify-content: center; */
align-items: center;
}

.filter-container button {
  background-color: rgba(224,7,7,1);
  color: #fff;
  box-shadow: 0 0 8px rgba(10, 0, 10, 0.30);
  border-color: transparent;
  width: fit-content;
  font-size: 20px;
}

.filters label {
    display: flex;
}


.some-images {
    display: flex;
    height: 25rem;
    width: 100%;
    background-color: lightgray;
    justify-content: center;
    background-image:
        linear-gradient(
         transparent, black
    );
    /* &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, transparent 0%, black 100%);
    } */
}
.some-images img {
    display: flex;
    height: 25rem;
    width: 100%;

}

.individual-image {
    display: flex;
    width: 33.33%;
    opacity: .8;

}

.over-images {
    display: flex;
    justify-content:space-evenly;
    width: 100%;
    position: absolute;
    top: 200px;
}

.mini-container {
    display: flex;
    flex-direction: column;
    width: 35rem;
    height: 15rem;
    /* background-color: pink; */
    /* background-color: rgba(245, 245, 245, 0.139); */
    color: white;
    font-size: 24px;
}

.mini-container h3 {
    color: white;
    font-family: 'Poppins','Helvetica Neue',Helvetica,Arial,sans-serif;;
    font-size: 48px;
    /* background-color: red; */
}

.mini-container div {
    color: white;
    font-family: 'Poppins','Helvetica Neue',Helvetica,Arial,sans-serif;
}

.mini-container button {
    background-color: transparent;
    color: white;
    font-family: 'Poppins','Helvetica Neue',Helvetica,Arial,sans-serif;
    width: 3rem;
    border-color: white;

}


.mini-images {
    display: flex;
    align-items: flex-end;
}

.mini-images button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    border-radius: 5px;
    width: 15rem;
    color: rgba(255, 255, 255, 1);
    font-size: x-large;
    background-color: transparent;
    border-color: white;
}

.mini-images button:hover {
    background-color: rgba(245, 245, 245, 0.584);
    transition: 1s;
}

.leftReviews-container {
    /* background-color: purple; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: solid 1px rgb(167, 167, 167);
}

.review-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
}



.mini-reviews {
    display: flex;
}

#hours-edit {
    display: flex;
    gap: 5px;
}
.allPinsPage {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 50px;
  }
  .places {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 500px;
  }

  .placeIndexItem {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    width: 600px;
    border-bottom: 1px solid lightgray;
    padding: 10px;
    color: black;
  }

  .placeIndexItem h3 {
    font-weight: 700;
  }

  .placeIndexItem:hover {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
  .placeRightPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 10px;
  }

  .places img {
    width: 250px;
    height: 250px;
    border-radius: 5px;
  }
  .descriptionAllPins {
    height: 50px;
    font-size: small;
    width: 330px;
    overflow-wrap: break-word;
    overflow-y: auto;
  }


  @media screen and (max-width: 900px) {
    .card-info {
        font-size: smaller;

    }
}
  @media screen and (max-width: 700px) {
    .maps-api-container {
        display: none;
    }
    .all-cards-container{
        width: 100%;
    }
}
