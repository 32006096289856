.image-modal {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 5rem;

    width: 100rem;
    /* width: 100%; */
    /* background-color: red; */
}

.image-modal h1 {
    /* background-color: black; */
    display: flex;
    width: 100%;
    justify-content: center;
}


/* .all-images-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    background-color: gray;
    gap: 10px;
    margin: 20px
} */

.all-images-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5px;
    grid-auto-rows: minmax(100px, auto);
    width: 100%;
    background-color: rgb(222, 222, 222);
    padding-bottom: 1%;
}

.all-images-container::-webkit-scrollbar{
    display: none;
}

.all-images-container div {
    display: flex;
    justify-content: center;
}

.all-images-container img {
    /* height: 13rem; */
    aspect-ratio: 3/2;
    /* gap: 10px; */
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: 90%;
    /* height: 100%; */
    max-width: 90%;
    max-height: 100%;
    border-radius: .5rem;
    /* background-color: red; */
}
