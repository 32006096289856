/* TODO Add site wide styles */
html, body {
    margin: 0;
}


#root {
    min-height: 100vh
}

#body {
    margin: none;
}

.loading {
    background: white url(../public/loader-2_food.gif) no-repeat center center;
    /* background-color: #124c62; */
    /* border-radius: 100%; */
    height: 100vh;
    width: 100%;
  }
/* .loading {
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
    background-color: #124c62;
    border-radius: 100%;
    height: 6em;
  } */

  .spinbox {
    align-items: center;
    background-color: white;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
  }


  /* @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0.5;
      transform: scale(1);
    }
  } */

#page-container {
    position: relative;
    min-height: 100vh;
}

/* #content-wrap {
  padding-bottom: 7.5rem;
} */

#footer {
  position: relative;
  bottom: 0;
  width: 100vw;
  height: 9.5rem;            /* Footer height */
  background-color: #f7f7f7;
  /* background-color: black; */
    color: black;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    /* width: 100%; */
    margin-top: 6rem;
    font-size: 18px;
}

#Footer-div {
    display: flex;
    /* min-height: 100vh; */
    flex-wrap: wrap;
    justify-content: center;
    /* margin-top: 10px; */
  }

  #dev {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }

  #dev div {
    font-weight: bold;
    margin-bottom: 5px;
  }

  #dev a {
    color: rgb(231, 92, 194);
    text-decoration: none;
    margin: 2px;
  }

  #dev a:hover {
    text-decoration: underline;
  }

.Home {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* max-width: 100vw; */
    overflow-x: hidden;
    /* background-color: red; */
    /* height: 20rem; */
}

.background {
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* height: 40rem; */
    /* max-width: 120rem; */
    position: absolute;
    top: 1px;
    width: 100%;
    padding-top: 325px;
    padding-bottom: 325px;
    /* background-image: url(https://www.lyledc.com/media/pjbkwhfy/lyle-hotel-1914-web-wide.jpg?anchor=center&mode=crop&quality=70&width=1280&height=647&rnd=133057541708130000); */
    background-image: url("../../react-app/src/components/HomePage/YumHome.png");
    /* background-repeat: no-repeat ; */
    background-size: cover;
    background-position: center;
    /* background-blend-mode: multiply; this will blend background color with image colors */
    /* z-index: 1; */
}

.background img {
    /* min-width: 70rem; */
    /* height: 100%; */
    width: 100%;
    object-fit: cover;
    /* aspect-ratio: 3/2; */
    overflow: hidden;
    object-position: center;
}

.recent-activity {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: purple; */
    /* height: fit-content; */
    min-height: 60rem;
    margin-top: 35rem;
    /* margin-top: 45%; */
    /* width: 100%; */
    /* padding-bottom: 2rem; */
    border-bottom: solid 1px lightgray;
}

.container-categories {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Categories {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 4rem;
    grid-auto-rows: 14rem; /* Reduce the row height */
    /*
    justify-content: center; */
    /* height: 40rem; */
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    /*
    width: 70%; */
}

.category-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3%;
    box-shadow: 0px 0px 10px -5px;
    height: 50%;
    min-width: 150px;
    width: 100%;
    padding-top: 1%;
    height: 10rem;
    transition: .5s;
    border-radius: 5px;
    max-width: 275px;
}

@media screen and (max-width: 810px) {
    .Categories {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 3rem;
    }
}
@media screen and (max-width: 600px) {
    .Categories {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3rem;
    }
}

.category-box:hover {
    box-shadow: 0px 0px 10px -2px;
    cursor: pointer;
}

#not-allowed:hover {
    cursor: not-allowed;
}



.details-container {
    display: flex;
    flex-direction: column;
    /* background-color: purple; */
    height: 100rem;
    align-items: normal;
}

/* .some-images {
    display: flex;
    justify-content: center;
    height: 20rem;
    background-color: blue;
    width: 100%;
} */

.location-details {
    display: flex;
    flex-direction: row;
    /* background-color: indigo; */
    justify-content: space-around;
    height: 80rem;
    align-items: normal;
}

.details-right {
    /* background-color: rgb(138, 91, 5); */
    /* align-self: normal; */
    position: sticky;
    position: -webkit-sticky;
    top: 70px;
    width: 20%;
    height: fit-content;
    padding: 2rem;
    padding-top: 1rem;
    border-radius: 4px;
    border: 1px solid #ebebeb;
    margin-top: 1.8rem;
    margin-left: 1rem;
    /* min-width: 250px; */
    /* padding-top: 35px; */
}
.details-left {
    /* background-color: orange; */
    display: flex;
    flex-direction: column;
    width: 45%;
    /* align-items: center; */
    justify-content: flex-start;
    height: 30rem;
    padding-top: 2rem;
}

.topLeft {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding-bottom: 1.5rem;
    border-radius: 4px;
    /* border-bottom: 1px solid #ebebeb; */
}

.topLeft button {
    height: fit-content;
    width: fit-content;
    min-height: 3rem;
    background-color: white;
    border-width: thin;
    color: rgba(45,46,47,1);
    padding-left: 15px;
    padding-right: 15px;
    border-color: #ebebeb;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 4px;
}

.WriteReview button {
    background-color: rgba(224,7,7,1);
}
.WriteReview button:hover {
    background-color: rgb(243, 8, 8);
    transition: .8s;
}

.Website-Section {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
}
.Website-Section div {
    overflow: hidden;
    word-wrap: break-word;
}

.phone-section {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
}

.message-section {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
}


.map-container {
    width: 100%;
    height: 100vh;
}
