.outside {
    margin-bottom: 5rem;
}


.card-container {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    /* background-color: red; */

    /* border-style: double; */
    /* border-color: blue; */
    border-width: 1px;
    height: 18rem;
    max-height: 18rem;
    width: 97%;
    border-radius: 5px;
    /* overflow: hidden; */
    /* padding-top: 10px; */
    /* box-shadow: 0px 0px 15px -5px; */

    /* justify-items: center; */

    /* max-width: 16rem; */
    /* height: 22rem; */
    /* overflow: hidden; */
    /* flex-grow: 1; */
    /* box-shadow: 0px 0px 15px -5px; */
    /* transition: 0.5s; */
    /* border-top-right-radius: 20px; */
    /* border-top-left-radius: 20px; */
    /* border-radius: 20px; */
    /* background-color: white; */
    /* background-color: blue; */

}



/* #category {
    background-color: rgb(218, 214, 214);
    width: fit-content;
    border-radius: 3px;
    font-size: small;
    padding: 3px;
} */
.card-container:hover {
    box-shadow: 0px 1px 15px -5px;
    transition: .8s;
}

.card-info {
    display: flex;
    flex-direction: column;
    /* width: 50%; */
    padding-left: 5%;
}


.card-title {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.card-title h3 {
    height: 1.5rem;
    /* overflow-y: hidden; */
}

#random-rev-logo {
    display: flex;

}

#random-rev {
    display: flex;
    /* align-items: flex-start; */

    /* justify-content: center; */
    /* align-items: center; */
    overflow-y: scroll;
    overflow-wrap: break-word;
    height: 3.5rem;
}

#random-rev::-webkit-scrollbar{
    display: none;
}

.card-name h2 {
    margin-bottom: 1px;
}
.card-name h2:hover {
    text-decoration: underline;
}


#card-comment {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .2rem;
    /* height: 2.5rem; */
}

.image-container {
    display: flex;
    align-items: center;
    width: 40%;
    min-width: 40%;
    min-width: 200px;
    max-width: 300px;
}

.card-rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    color: gray;
    font-size: medium;
}

#numReview {
    display: flex;
    align-items: center;
    height: 100%;
}

.image-container img {
    display: flex;
    /* overflow: hidden; */
    height: 90%;
    min-width: 100%;
    max-width: 100%;
    /* flex-grow: 1; */
    max-height: 100%;
    /* box-shadow: 0px 0px 15px -5px; */
    border-radius: 8px;
    object-fit: fill;
    aspect-ratio: 3/2;
    margin-left: 5%;
}

@media screen and (max-width: 700px) {
    .maps-api-container {
        display: none;
    }

}
