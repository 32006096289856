#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  z-index: 100;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color:white;
  border-radius: .5rem;
  max-width: 95%;
  max-height: 80%;
  display: flex;
  justify-content: center;
  top: 10%;
  overflow-y:scroll;
}

#modal-content::-webkit-scrollbar{
  display: none;
}

.loc-rem-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

}

.loc-rem-modal button {
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  margin-left: 8px;
  width: 100%;
}
