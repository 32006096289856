.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}

#badLink:hover {
  cursor: not-allowed !important;
}

.navStart {
  /* background-color: red; */
  /* align-content: center; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
  max-width: 120rem;
  width: 100%;
  }

  .navbar {
    /* background-color: blue; */
    width: 100%;
    display: flex;
    align-items: center;
    /* align-content: center; */
    flex-direction: row;
    justify-content:space-between;
    /* padding-left: 10rem; */
    /* padding-right: 8%; */
    /* padding-left: 10rem; */
    color: transparent;
    height: 100px;
    /* border: solid; */
    /* border-bottom: 2px solid rgba(128, 128, 128, 0.408); */
    /* border-bottom: 2px solid lightgrey; */
    padding-left: 6%;
    padding-right: 6%;
    z-index: 100;

}

.logo {
  display: flex;
  flex-direction: row;
  width: fit-content;
  text-decoration: none;
}

.logo NavLink {
  text-decoration: none;
}

.home-select {
  /* background-color: blue; */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  color: red;
  text-decoration: none;
}

.home-select h1 {
   font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-family: 'Kanit', sans-serif;
  display: flex;
  flex-direction: row;
  font-family: 'Kanit', sans-serif;
  font-weight: 600;
  color: rgba(45,46,47,1);
  width: 5rem;
  list-style: none;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  font-size: 30px;
}

/* .logo button{
  font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-family: 'Kanit', sans-serif
  display: flex;
  flex-direction: row;
  font-family: 'Kanit', sans-serif;
  font-weight: 600;
  color: rgba(45,46,47,1);
  width: 5rem;
  list-style: none;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  font-size: 30px;

} */

.fa-utensils {
  /* background-color: red; */
  color: rgb(241, 7, 7);
  font-size: 30px;
}

.profile-dropdown {
  color: black;
}

.nav-modals {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-radius: 2px;
}

.nav-modals button {
  display: flex;
  align-items: center;
  width: max-content;
  background-color: transparent;
  border-color: transparent;
  color: rgba(45,46,47,1);
  font-size: 14px;
  font-weight: 700;
  color: rgba(224,7,7,1);
  border-radius: 4px;
}

#logout-button {
  color: rgba(224,7,7,1);
}

.nav-modals button:hover {
    background-color: rgba(190, 190, 190, 0.584);
    transition: .5s;
}


.profile-dropdown {

  /* position: absolute; */
  top: 4.2rem;
  right: .5%;


  list-style: none;
  background-color: white;
  box-shadow: 0 0 8px rgba(10, 0, 10, 0.30);
  border-radius: .2rem;
  height: fit-content;
  width: 13rem;
  max-width: 30rem;
  padding: 1rem;
  overflow: hidden;
  z-index: 100;
  color: rgba(45,46,47,1);
  font-size: 14px;
  font-weight: 600;
}

.profile-dropdown li {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 1rem;
  /* justify-content: center; */
  /* align-items: center; */
}

.profile-dropdown button {
  width: 100%;
}

.profile-dropdown li:hover {
    background-color: rgb(216, 215, 215);
    transition: .5s;
    cursor: pointer;
}

.in-or-out {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.in-or-out button {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* .in-or-out button:hover {
  background-color: darkgray;
  transition: .8s;
} */

.user-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 3rem;
}

.user-menu button {
  font-size: 35px;
  /* width: 100%; */
}

.user-menu button:hover {
  background-color: transparent;
}

/* .fa-circle-user {
  color: lightgray;
  background-color: rgb(161, 161, 161);
  border-radius: 2rem;
} */

#prof-user {
  color: lightgray;
  background-color: rgb(161, 161, 161);
  border-radius: 2rem;
}

.search-container {
  /* width: max-content; */
  width: 100%;
}

.search-bar {
  display: flex;
  justify-content: center;
  height: 3rem;
  width: 100%;
  /* background-color: black; */
}

.search-bar input {
  height: 100%;
  width: 75%;
  box-shadow: 0 0 8px rgba(10, 0, 10, 0.30);
  background-color: #ffffff;
  border-color: transparent;
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;

}

.search-bar button {
  height: 113%;
  width: 24%;
  background-color: rgba(224,7,7,1);
  color: #fff;
  box-shadow: 0 0 8px rgba(10, 0, 10, 0.30);
  border-color: transparent;
  width: 3.5rem;
  font-size: 20px;
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;

}
.search-bar button:hover {
  background-color: rgb(241, 7, 7);
  transition: 1s;
  cursor: pointer;
}

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found img {
  max-width: 50%;
}

@media screen and (max-width: 1030px ) {
  .search-container {
    /* display: none; */
    width: 40%;
  }

  .navStart {
    justify-content:flex-start;
  }

  .navbar {
    justify-content: space-around;
    padding-left: 0;
    padding-right: 0;
    width: 100vw;
  }
}

@media screen and (max-width: 700px) {
  .search-container {
    display: none;
  }

}
