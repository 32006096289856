.error-message {
    display: flex;
    flex-direction: column;
    color: red;
    list-style: none;
    display: flex;
    justify-content: center;
    padding-right: 1.4rem;
}

.addLocationMain form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding:15px;
    max-width: 500px;
    width: 350px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, .5);
    /* border-style: solid; */
}
.UpdateLocationMain form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, .5);
    padding:15px;
    max-width: 500px;
    width: 350px;
}


.submit-form {
    background-color: rgb(241, 7, 7);
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-right: 8px;
    max-width: 500px;
    width: 350px;
}

.submit-form:hover {
    background-color: rgba(241, 7, 7, 0.915);
}

.addLocationMain {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    /* border-style: solid; */
    /* width: fit-content; */
    align-items: center;

}
.UpdateLocationMain {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.Form-Title{
    display: flex;
    justify-content: center;
    color: #aa0f14;
}

.addLocationForm label {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-bottom: 5px;
    justify-items: center;
}
.UpdateLocationForm label {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-bottom: 5px;
}

.Label {
    display: flex;
    flex-direction: column;
}

.description-form {
    max-width: 21.5rem;
}

.remove-button {
    background-color: darkred;
}

/* input#file-input {
    display: none;
} */

input#file-input + label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    background-color: rgb(148, 135, 135);
    padding: 8px;
    color: rgb(255, 255, 255);
    border: 2px solid gray;
    cursor: pointer;
    /* border-radius: 9px; */
}

#file-display {
    display: flex;

}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
.custom-file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 2px dashed #ccc;
    border-radius: 8px;
    cursor: pointer;
  }

  .custom-file-upload input[type="file"] {
    display: none;
    height: 100%;
    width: 100%;
    background-color: red;
  }

  .custom-file-upload span {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
  }

  /* Style when hovering over the drop area */
  .custom-file-upload:hover {
    background-color: #f9f9f9;
  }

  /* Style when a file is being dragged over */
.custom-file-upload.dragover {
    background-color: #e0e0e0;
  }

  .preview-image {
    max-width: 100%;
    max-height: 200px; /* Adjust the height as needed */
    display: none; /* Initially hide the image */
  }

  .custom-file-upload.active .preview-image {
    display: block; /* Show the image when the file is selected or dropped */
    margin-top: 10px; /* Add spacing between the file input and the preview */
  }
