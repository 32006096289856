.prof-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: red; */
    height:80rem;
    height: fit-content;
    overflow: hidden;

}


.prof-container {
    /* background-color: blue; */
    width: 100%;
    display: flex;
    justify-content: center;
    height: 16rem;
    font-size: x-large;
    background: #f5f5f5;
    gap: 2rem;
    align-items: center;
    overflow-x: hidden;
}

.prof-img-container {
    border: 1px solid rgba(128, 128, 128, 0.39);
    border-radius: .5rem;
    /* margin-top: 6rem; */
    /* background-color: blue; */
    /* width: 100%; */
    height: 60%;
    /* max-width: 20%; */
    /* min-width: 200px; */
    min-width: 200px;
    max-width: 200px;
}

.prof-img-container img {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    aspect-ratio: 3/2;
}

.prof-center {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    /* padding-right: 15rem; */
    gap: .4rem;
    width: 30%;
    max-width: 500px;
    min-width: 150px;
}

#prof-name {
    font-size: xx-large;
}
#city-state {
    font-size: medium;
}

.prof-right {
    border-left: 1px solid;
    padding-left: 1rem;
    /* background-color: green; */
    width: 30%;
    max-width: 300px;
    font-size: x-large;
}

@media screen and (max-width: 800px) {
    .prof-right {
        font-size: large;
    }
}
@media screen and (max-width: 600px) {
    .prof-right {
        font-size: medium;
    }
}
@media screen and (max-width: 530px) {
    .prof-right {
        display: none;
    }
}


.prof-details-container {
    display: flex;
    width: 100%;
    max-width: 1880px;
    justify-content: space-evenly;
    /* gap: 5%; */
    overflow: hidden;
}

.prof-details-tabs {
    /* background-color: red; */
    width: 530px;
}
@media screen and (max-width: 900px) {
    .prof-details-container {
        gap: 5%;
    }
}

.prof-reviews {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* .prof-fav-container {
    width: 40%;
} */

.prof-page-buttons {
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: center;
}

.prof-page-buttons button {
    height: 2.5rem;
    width: 15rem;
    background-color: white;
    border-style: solid;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
    padding-left: 15px;
    padding-right: 15px;
    border-color: #e6e6e6;
    border-collapse: collapse;
}

@media screen and (max-width: 630px) {
    .prof-page-buttons button {
        width: 10rem;
    }
}
/* .prof-fav-container {
    background-color: blue;
} */
/* @media screen and (max-width: 570px) {
    .prof-fav-container {
        background-color: blue;
    }
} */

/* .prof-page-buttons button:active {
    background-color: #e5e5e5;
} */
/* .prof-page-buttons button:hover {
    background-color: rgba(224,7,7,1);
    transition: 1s;
} */

.rev-card {
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

#each-rev {
    width: 100%;
}

#rev-mutate {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

#rev-mutate button {
    background-color: transparent;
    border-color: transparent;
}
#rev-mutate button:hover {
    background-color: rgb(207, 219, 219);
}

#fav-header {
    display: flex;
    justify-content: center;
}
